<template>
  <b-modal
    id="lock-modal"
    v-model="showModal"
    dialog-class="product-change-modal"
    title-class="product-change-title"
    content-class="product-change-content"
    footer-class="product-change-footer"
    scrollable
    header-bg-variant="light pb-0"
    @change="toggle"
  >
    <product-select
      :value="value"
      :categories="categories"
      :human-products="found_products"
      :show-categories="showCategories"
      @input="$emit('input', $event)"
      @productClick="$emit('productClick')"
    />

    <template #modal-footer>
      <div class="d-none">
        <!-- PLACEHOLDER -->
      </div>
    </template>

    <template #modal-title>
      <div>
        <p class="product-select-modal-title text-uppercase">
          {{ $t('cashier.modalProductSelect.title') }}
        </p>

        <div class="form-group has-search">
          <span class="fas fa-search form-control-feedback m-2" />

          <input
            v-model="search_product"
            type="text"
            class="form-control"
            :placeholder="$t('global.search') + '...'"
          >
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { VuexTypes } from '@/store/types';
import HumanProductsService from '@/services/human-products-service';
import ProductSelect from '@/modules/ProductSelect/ProductSelect.vue';

export default {
  name: 'ModalProductSelect',

  components: {
    ProductSelect,
  },

  inject: ['_cashDeskId'],

  props: {
    value: {
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      showModal: false,
      search_product: '',
      show_categories: true,
    };
  },

  computed: {
    ...mapState('cashierCategories', {
      categories: 'list',
    }),
    ...mapState('cashierHumanProducts', {
      humanProducts: 'byCategory',
    }),
    found_products() {
      let filtered_products = {};
      for (const [key, value] of Object.entries(this.humanProducts)) {
        let filtered = value.filter((product) => product.name.toLowerCase().includes(this.search_product.toLowerCase()));
        if (filtered.length !== 0) {
          filtered_products[key] = filtered;
        }
      }
      return filtered_products ? filtered_products : this.humanProducts;
    },
    showCategories() {
      if (this.search_product == '') {
        return true;
      }
      return false;

    },
  },

  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        this.showModal = newVal;
      },
    },
    'order._loading': {
      immediate: true,
      handler(newVal) {
        if (!newVal) this.close();
      },
    },
  },

  mounted() {
    this.loadData();
  },

  methods: {
    ...mapActions('cashierCategories', {
      setCategories: VuexTypes.CASHIER_CATEGORIES_ACTION_SET,
    }),
    ...mapActions('cashierHumanProducts', {
      setHumanProducts: VuexTypes.CASHIER_HUMAN_PRODUCTS_ACTION_SET,
    }),
    toggle() {
      this.$emit('close');
    },
    close() {
      this.showModal = false;
      this.toggle();
    },
    confirm() {
      this.lockOrder(this.order);
    },
    loadData() {
      HumanProductsService.forProductSelect(this._cashDeskId)
        .then((resp) => {
          this.setCategories(resp.data.categories);
          this.setHumanProducts(resp.data.human_products);
        });
    },
    input(newProduct) {
      this.$emit('input', newProduct);
    },
  },
};
</script>

<style lang="scss">
.product-change-modal {
  margin-left: 5vw;
  width: 90vw !important;
  max-width: 100vw;
}

.product-change-title {
  width: 45vw;
}

.product-change-content {
  border-radius: 0.5rem;
}

.product-change-footer {
  border: 0;
}
</style>

<style lang="scss" scoped>
.has-search .form-control {
  padding-left: 30px;
  border-radius: 5px;
  width: 86vw;
  overflow: hidden;
}

@media screen and (max-width: 650px) {
  .has-search .form-control {
    width: 80vw;
  }
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.product-select-modal-title {
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  text-align: left;
}
</style>
